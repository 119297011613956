<template>
  <v-layout wrap>
    <v-flex xs8 style="padding: 20px 10px 20px 20px">
      <system-table
        :loading="loading"
        :title="$t('workspace.title', locale)"
        :headers="[
          { text: $t('workspace.name', locale), value: 'ident' },
          { text: $t('workspace.users', locale), value: 'users' },
        ]"
        :onGetData="handleGetData"
        :tableData="workspaces"
        :onSearch="handleSearch"
        :onEditItem="handleEdit"
        :onCloneItem="handleClone"
        :startIndex="startIndex"
        :itemsPerPage="itemsPerPage"
        :count="count"
        :hideAddButton="true"
      />
      <div style="padding: 20px 0;">
        <brightsign-sync />
      </div>
    </v-flex>
    <v-flex xs4 style="padding: 20px 20px 20px 10px">
      <v-card
        style="min-height:calc(100vh - 104px);"
        elekvation="0"
      >
        <v-card-text>
          <workspace-form
            :isClone="isClone"
            :editedID="editedID"
            :showForm="showForm"
            :onDelete="handleDelete"
            :onSave="handleSave"
            :onNew="handleEdit"
            :onClone="handleSaveClone"
            :onClose="handleCloseForm"
          />
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import SystemTable from '@/components/systemTable/Index'
import BrightsignSync from './BrightsignSync'
import WorkspaceForm from './workspaceForm/Index'
export default {
  components: {
    SystemTable,
    BrightsignSync,
    WorkspaceForm
  },
  data: () => ({
    loading: false,
    search: '',
    workspaces: [],
    startIndex: 0,
    itemsPerPage: 5,
    count: null,
    isClone: false,
    editedID: null,
    showForm: null
  }),
  computed: {
    ...mapState('app',['locale']),
    paramID () {
      return this.$route.params.id
    },
    isDev () {
      return process.env.VUE_APP_ENV === 'development'
    },
  },
  mounted () {
    //if (this.isDev) this.search = 'descu'
    this.handleGetData()
    if (this.paramID) this.handleEdit(this.paramID)
  },
  methods: {
    handleSearch (v) {
      this.search = v
      this.handleGetData(0)
    },
    handleGetData (startIndex) {
      this.workspaces = []
      this.loading = true
      if (startIndex >=0 ) this.startIndex = startIndex
      api.getAll ('players', 'v1/system/workspaces/', this.startIndex, this.itemsPerPage, this.search)
        .then(response => {             
          this.count = response.count
          this.workspaces = response.data
          this.loading = false
        })
    },
    handleEdit (v) {
      this.isClone = false
      this.editedID = v
      this.showForm = true
    },
    handleClone (v) {
      this.isClone = true
      this.editedID = v
      this.showForm = true
    },
    handleDelete (v) {
      if (v) 
        api.deleteItem ('players', 'v1/system/workspaces/', v)
          .then(() => {                      
            this.handleGetData(this.startIndex)
            this.handleCloseForm()
          })
    },
    handleSaveClone (v) {
      api.updateItem ('players', 'v1/system/workspaces/', v.ID.concat('/clone'), v)
        .then(() => {          
          this.handleGetData(this.startIndex)
          this.handleCloseForm()
        })  
    },
    handleSave (v) {
      if (v.ID)
        api.updateItem ('players', 'v1/system/workspaces/', v.ID, v)
          .then(() => {          
            this.handleGetData(this.startIndex)
            this.handleCloseForm()
          })  
      else
        api.addItem ('players', 'v1/system/workspaces/', v)
          .then(() => {          
            this.handleGetData(this.startIndex)
            this.handleCloseForm()
          })
    },
    handleCloseForm () {
      this.showForm = false
    },
  }
}
</script>
