<template>
  <div v-if="!showForm">
    <v-btn
      color="primary"
      block
      @click="() => { onNew(null) }"
    >
      {{ $t('common.add', locale) }}
    </v-btn>
  </div>
  <div v-else-if="showForm">
    <v-toolbar
      elevation="0"     
    >
      <v-icon large style="margin-right:10px;">mdi-television</v-icon>
      <v-toolbar-title>
        {{ $t('common.workspace', locale) }}
      </v-toolbar-title>
    </v-toolbar>
    <v-progress-linear indeterminate v-if="loading" />
    <v-container v-else-if="editedItem">
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-text-field
            v-bind="{ 'outlined' : true}"
            v-model="editedItem.Name"
            :label="$t('sysUser.name', locale)"
            hide-details
          />
            <small>{{editedID}}</small>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-text-field
            v-bind="{ 'outlined' : true}"
            v-model="editedItem.NetworkID"
            label="Network"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-expansion-panels focusable>
            <v-expansion-panel 
              @click="handleExpand"
            >
              <v-expansion-panel-header>
                {{ $t('workspace.users', locale) }}
              </v-expansion-panel-header>
              <v-expansion-panel-content style="padding-top:16px;">
                <select-sys-users-permissions
                  area="players"
                  :workspaceID="editedItem.ID"
                  :onChangeUser="handleChangeUser"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!--select-sys-users
            area="dtouch"
            :workspaceID="editedItem.ID"
            :onChangeUser="handleChangeUser"
          /-->
        </v-col>
      </v-row>
    </v-container>
    <action-buttons
      v-if="editedItem"
      :id="editedItem.ID"
      :onDeleteItem="isFloatingSetting ? null : handleDelete"
      :onSaveItem="handleSave"
      :onClose="onClose"
      :locale="locale"
    />
  </div>
</template>
<script>
const defaultItem = {
  Name: '',
}

import { mapState } from 'vuex'
import api from '@/services/api'
//import SelectSysUsers from '@/components/SelectSysUsers'
import SelectSysUsersPermissions from '@/components/SelectSysUsersPermissions'
import ActionButtons from '@/components/ActionButtons'
/*
import MessagesForm from './MessagesForm'
import ContentTypeForm from './ContentTypeForm'
import AuthenticationForm from './AuthenticationForm'
import ContentmanagerBookForm from './ContentmanagerBookForm'
import MeteoForm from './MeteoForm'
import DfileForm from './DfileForm'
import FoodDrinkForm from './FoodDrinkForm'
import MenuExtFoodForm from './MenuExtFoodForm'
import TotemForm from './totemForm/Index'
import ScreensaverForm from './ScreensaverForm'
import ActivityDviewcontentForm from './ActivityDviewcontentForm'
import ShowDviewcontentForm from './ShowDviewcontentForm'
import ParrillaDviewcontentForm from './ParrillaDviewcontentForm'
import DesignForm from './DesignForm'
import HotelequiaForm from './HotelequiaForm'
import IberostarForm from './IberostarForm'
import CustomUrlBackForm from './CustomURLBackForm'
import ActtivForm from './ActtivForm'
import DialForm from './DialForm'
import WelcomeFormForm from './welcomeFormForm/Index'
import WeeklyMenuForm from './WeeklyMenuForm'
import RequestsForm from './RequestsForm'
import IssuesForm from './IssuesForm'*/
export default {
  name: 'WorkspaceForm',
  components: {
    ActionButtons,
    SelectSysUsersPermissions,
    /*
    MessagesForm,
    //SelectSysUsers,
    ContentTypeForm,
    AuthenticationForm,
    ContentmanagerBookForm,
    MeteoForm,
    DfileForm,
    FoodDrinkForm,
    MenuExtFoodForm,
    TotemForm,
    ScreensaverForm,
    ActivityDviewcontentForm,
    ShowDviewcontentForm,
    ParrillaDviewcontentForm,
    DesignForm,
    HotelequiaForm,
    IberostarForm,
    CustomUrlBackForm,
    ActtivForm,
    DialForm,
    WelcomeFormForm,
    WeeklyMenuForm,
    RequestsForm,
    IssuesForm,*/
  },
  props: {
    isClone: {
      type: Boolean,
      default: false,
    },
    editedID: {
      type: String,
      default: null
    },
    showForm: {
      type: Boolean,
      default: false
    },
    onDelete: {
      type: Function,
      required: true
    },
    onSave: {
      type: Function,
      required: true
    },
    onNew: {
      type: Function,
      required: true,
    },
    onClone: {
      type: Function,
      required: true,
    },
    onClose: {
      type: Function,
      required: true
    },
    isFloatingSetting: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      loading: false,
      editedItem: null,
      timezones: null,
      languages: [],
    }
  },
  computed: {
    ...mapState('app',['locale']),
  },
  watch: {
    showForm (v) {
      if (v) {
        if(this.editedID) this.handleGetData()
        else this.editedItem = defaultItem
      }
    },
    editedID (v) {
      if (this.showForm) {
        if (v) this.handleGetData()
        else this.editedItem = defaultItem

      }
    },
  },
  mounted () {
    if (this.editedID) this.handleGetData()
  },
  methods: {
    checkPermissions(v) {
      for (const item of v) 
        if (this.editedItem.Permission.indexOf(item) >= 0) return true


      return false
    },
    handleGetData () {
      this.loading = true
      api.getItem ('players', 'v1/system/workspaces/', this.editedID)
        .then(response => {

          if (this.isClone) {
            response.Name += ' [copia]'
            response.Domain += ' [copia]'
          }
          response.Permission = JSON.parse(response.Permissions)
          this.editedItem = response
          this.loading = false
        })
    },
    handleChangeUser (v) {
      this.editedItem.Users = v
    },
    /*
    handleChangeContentType (v) {
      this.editedItem.Permission = v
    },
    handleChangeAuthData (v) {
      this.editedItem.authData = v
    },
    handleChangeCMBookData (v) {
      this.editedItem.cMBookData = v
    },
    handleChangeMeteoData (v) {
      this.editedItem.meteoData = v
    },
    handleChangeDfileData (v) {
      this.editedItem.dfileData = v
    },
    handleChangeFoodDrinkData (v) {
      this.editedItem.foodDrinkData = v
    },
    handleChangeMenuExtFoodData (v) {
      this.editedItem.menuExtFoodData = v
    },
    handleChangeTotemData (v) {
      this.editedItem.totemData = v
    },
    handleChangeScreensaverData (v) {
      this.editedItem.screensaverData = v
    },
    handleChangeActivityDviewcontentData (v) {
      this.editedItem.activityDviewcontentData = v
    },
    handleChangeParrillaDviewcontentData (v) {
      this.editedItem.parrillaDviewcontentData = v
    },
    handleChangeShowDviewcontentData (v) {
      this.editedItem.showDviewcontentData = v
    },
    handleChangeDesignData (v) {
      this.editedItem.designData = v
    },
    handleChangeHotelequiaData (v) {
      this.editedItem.hotelequiaData = v
    },
    handleChangeIberostarData (v) {
      this.editedItem.iberostarData = v
    },
    handleChangeCustomURLBackData (v) {
      this.editedItem.customURLBackData = v
    },
    handleChangeActtivData (v) {
      this.editedItem.acttivData = v
    },
    handleChangeDialData (v) {
      this.editedItem.dialData = v
    },
    handleChangeWelcomeFormData (v) {
      this.editedItem.welcomeFormData = v
    },
    handleChangeWeeklyMenuData (v) {
      this.editedItem.weeklyMenuData = v
    },
    handleChangeRequestsData (v) {
      this.editedItem.requestsData = v
    },
    handleChangeIssuesData (v) {
      this.editedItem.issuesData = v
    },
    handleChangeMessagesData (v) {
      this.editedItem.messagesData = v
    },
    */
    handleSave () {
      if (this.isClone) this.onClone(this.editedItem)
      else this.onSave(this.editedItem)
    },
    handleDelete () {
      this.onDelete(this.editedItem.ID)
    },
    handleGoTo (v) {
      window.open(v)
    },
  },
}
</script>
<style scoped>
</style>

