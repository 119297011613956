<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          BrightsignSync
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Brightsign Sync
        </v-card-title>

        <v-card-text>
          <v-row
            v-if="networks"
            no-gutters
          >
            <v-col
              v-for="(item, index) in networks"
              :key="`${index}`"
              cols="12"
              sm="12"
              md="12"
            >
              <v-row
                no-gutters
              >
                <v-col
                  cols="12"
                  sm="1"
                  md="1"
                >
                  <v-checkbox
                    v-model="selectedNetworks"
                    :value="item.id"
                    hide-details
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="5"
                  md="5"
                  style="padding-top:20px;"
                >
                  {{item.id}}
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                  style="padding-top:30px;"
                >
                  <v-progress-linear
                    v-if="item.loading"
                    indeterminate
                    color="primary"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  style="padding-top:20px;"
                  :style="{ color: item.status === 'OK' ? 'green' : 'red' }"
                >
                  <b>{{!item.loading && item.status ? `[${item.status}]` : ''}}</b>
                  {{ !item.loading && item.count ? item.count.success : '' }} 
                  {{ !item.loading && item.count && item.count.error ? ` -> (${item.count.error})` : '' }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>

          <v-btn
            text
            @click="handleSelectAll"
          >
            TODOS
          </v-btn>
          <v-spacer/>
          <v-btn
            color="grey"
            text
            @click="dialog = false"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="handleSync"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import apiBrightsign from '@/services/apiBrightsign'
export default {
  data: () => ({
    dialog: false,
    networks: null,
    selectedNetworks: []
  }),
  mounted () {
    this.handleGetNetworks()
  },
  methods: {
    handleSelectAll () {
      this.selectedNetworks = this.networks.map(item => item.id)
    },
    handleGetNetworks () {
      this.selectedNetworks = []
      apiBrightsign.getAll(`/v1/system/cron`)
        .then(response => {
          this.networks = response.map(item => {
            return {
              id: item,
              loading: false,
              status: null,
            }
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    handleSync () {
      if (!this.selectedNetworks || this.selectedNetworks.length === 0) return
      for (const network of this.selectedNetworks) {
        const index = this.networks.map(item => item.id).indexOf(network)
        this.networks[index].loading = true

        apiBrightsign.put(`/v1/system/cron/${network}`)
          .then(response => {
            console.log(response.data.data)
            this.networks[index].loading = null
            this.networks[index].status = response.data.status
            this.networks[index].count = response.data.count
          })
          .catch(error => {
            console.log(error)
            this.networks[index].loading = null
          })
      } 
    },
  }
}
</script>
